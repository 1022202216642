import {CenterDiv, SmallSpan, VerticalSpacer} from "../components/Styling";

function Texts() {
    return (
        <CenterDiv>
            <h1>Einst bei einer Strandwanderung</h1>
            <par>
                Johannes und ich waren auf einer Strandwanderung in Schouwen-Duiveland ein
                wenig zurückgefallen. Papa war wie immer die Nase im Wind mit seinem
                unverkennbaren weiten Schritt ein wenig voraus. Maria auf der Schulter, Florian
                an der Hand. Andreas irgendwo und Mama dazwischen.
                <br/><br/>
                Plötzlich findet Johannes 10 Mark im Sand! Dann noch ein wenig Kleingeld. Dort
                liegt noch ein Schein. Das Sammlerfieber war geweckt und es kam ein Sümmchen
                zusammen, das unsere Fanatasie massiv anregte: was wir uns damit alles kaufen
                könnten...
                <br/><br/>
                In Renesse Strand angekommen schlägt Papa vor, uns alle auf ein Eis
                einzuladen. Alle sind begeistert - bis es ans zahlen geht. Martins Tasche hat
                ein Loch und die Tasche ist leer. Der Eisverkäufer nicht begeistert. Da wussen
                Johannes und ich sofort welche Quelle unseren Überraschubfsfund gespeist hatte.
                So bleibt am Ende alles in der Familie.
                <br/><br/>
            </par>
            <SmallSpan> Frei aus der Erinnerung von Stefan</SmallSpan>

            <VerticalSpacer />

            <h1>Nachruf des Helmholtz Zentrums</h1>
            <par>
                Wir trauern um unseren ehemaligen kaufmännischen
                Geschäftsführer Dr. jur. Martin Nettesheim, der am 21. Juli
                2024 im Alter von 88 Jahren verstorben ist. 
                <br/><br/>

                Dr. Martin Nettesheim war von September 1979 bis März 1994
                kaufmännischer Geschäftsführer des Hahn-Meitner-Instituts,
                einer Vorgängereinrichtung des HZB. Zugleich nahm er das Amt
                des Strahlenschutzverantwortlichen wahr. Er studierte
                Rechtswissenschaften an der Universität Bonn und promovierte
                anschließend an der Universität Köln.
                <br/><br/>

                In seinen 15 Jahren als Geschäftsführer hat Dr. Martin
                Nettesheim das HMI durch zahlreiche herausfordernde Zeiten
                geführt, die er gemeinsam mit der wissenschaftlichen Leitung im
                Sinne des Zentrums und der Belegschaft meisterte.
                <br/><br/>

                Eine Zäsur während seiner Amtszeit war der Fall der Berliner
                Mauer. Nach der Wiedervereinigung setzte sich Nettesheim mit
                dafür ein, eine Gruppe des Instituts für Elektronenphysik der
                ehemaligen Akademie der Wissenschaften der DDR in das HMI
                einzugliedern. Nach einem positiven Votum des
                Wissenschaftsrates wurde im Januar 1992 die Abteilung
                „Photovoltaik auf Siliziumbasis“ als Außenstelle des HMI in
                Berlin-Adlershof gegründet.
                <br/><br/>

                In Nettesheims Amtszeit fiel auch die Umrüstung des Berliner
                Forschungsreaktors BER II von 5 auf 10 Megawatt und der Einbau
                der „Kalten Quelle“. Die dafür notwendige Betriebsgenehmigung
                entwickelte sich im Berliner Senat zu einem Politikum und
                brachte das HMI in eine existenzielle Krise. Erst nach den
                Neuwahlen des Berliner Abgeordnetenhauses konnte die
                Genehmigung im März 1991 erteilt werden.
                <br/><br/>

                Dr. Martin Nettesheim war maßgeblich an der Sicherung des
                Fortbestands des Instituts beteiligt. Mit seinem politischen
                Verhandlungsgeschick trug er entscheidend dazu bei, das HMI
                durch diese schwierige Zeit zu führen. Dabei hatte er stets das
                Wohl der Belegschaft und den Erhalt der Arbeitsplätze im Blick.
                <br/><br/>

                Nach seiner Tätigkeit am HMI wechselte er in das
                Bundesministerium für Forschung und Technologie, wo er in der
                Außenstelle Berlin weiterwirkte.
                <br/><br/>

                Wir werden Dr. Martin Nettesheim stets ein ehrendes Andenken
                bewahren.  Unser tiefes Mitgefühl gilt seiner Familie und
                seinen Angehörigen.
                <br/><br/>
            </par>
            <SmallSpan>Original: <a href="https://www.helmholtz-berlin.de/pubbin/news_seite?nid=27766&sprache=de">Helmholtz Zentrum</a></SmallSpan>
        </CenterDiv>
    );
}

export default Texts;
